import { Modal, useMatchBreakpoints } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'

interface Props {
  children: any
  handleDismiss: any
  title?: string
}

const ConfirmSwapModalContainer: React.FC<Props> = ({ children, handleDismiss, title = 'Confirm Swap' }) => {
  const { isMobile } = useMatchBreakpoints()
  const { t } = useTranslation()

  return (
    <Modal
      style={{
        width: isMobile ? '100%' : '420px',
        height: 'auto',
        border: '10px solid #3a3b3e',
      }}
      title={t(title)}
      headerBackground="gradientCardHeader"
      onDismiss={handleDismiss}
    >
      {children}
    </Modal>
  )
}

export default ConfirmSwapModalContainer
