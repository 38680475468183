import { Card } from '@pancakeswap/uikit'
import styled from 'styled-components'

export const StyledPriceChart = styled(Card)<{
  $isDark: boolean
  $isExpanded: boolean
  $isFullWidthContainer?: boolean
}>`
  border-radius: 24px !important;
  width: full;
  padding-top: 36px;
  ${({ theme }) => theme.mediaQueries.sm} {
    padding-top: 8px;
    background: #1a1a1a;
    padding: 20px 5px;
    border-radius: ${({ $isExpanded }) => ($isExpanded ? '0' : '8px')};
    width: ${({ $isExpanded, $isFullWidthContainer }) => ($isFullWidthContainer || $isExpanded ? '100%' : '50%')};
    height: ${({ $isExpanded }) => ($isExpanded ? '100%' : '516px')};
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 24px;
    padding-top: 2px !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
    background: linear-gradient(
      to bottom,
      rgba(244, 118, 33, 0.1) 0%,
      rgba(244, 118, 33, 0.7) 24%,
      rgba(244, 118, 33, 0)
    );
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
  }
`

// StyledPriceChart.defaultProps = {
//   height: '70%',
// }
