import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M20.25 4.5V8.25C20.25 8.44891 20.171 8.63968 20.0303 8.78033C19.8897 8.92098 19.6989 9 19.5 9C19.3011 9 19.1103 8.92098 18.9697 8.78033C18.829 8.63968 18.75 8.44891 18.75 8.25V5.25H15.75C15.5511 5.25 15.3603 5.17098 15.2197 5.03033C15.079 4.88968 15 4.69891 15 4.5C15 4.30109 15.079 4.11032 15.2197 3.96967C15.3603 3.82902 15.5511 3.75 15.75 3.75H19.5C19.6989 3.75 19.8897 3.82902 20.0303 3.96967C20.171 4.11032 20.25 4.30109 20.25 4.5ZM8.25 18.75H5.25V15.75C5.25 15.5511 5.17098 15.3603 5.03033 15.2197C4.88968 15.079 4.69891 15 4.5 15C4.30109 15 4.11032 15.079 3.96967 15.2197C3.82902 15.3603 3.75 15.5511 3.75 15.75V19.5C3.75 19.6989 3.82902 19.8897 3.96967 20.0303C4.11032 20.171 4.30109 20.25 4.5 20.25H8.25C8.44891 20.25 8.63968 20.171 8.78033 20.0303C8.92098 19.8897 9 19.6989 9 19.5C9 19.3011 8.92098 19.1103 8.78033 18.9697C8.63968 18.829 8.44891 18.75 8.25 18.75ZM19.5 15C19.3011 15 19.1103 15.079 18.9697 15.2197C18.829 15.3603 18.75 15.5511 18.75 15.75V18.75H15.75C15.5511 18.75 15.3603 18.829 15.2197 18.9697C15.079 19.1103 15 19.3011 15 19.5C15 19.6989 15.079 19.8897 15.2197 20.0303C15.3603 20.171 15.5511 20.25 15.75 20.25H19.5C19.6989 20.25 19.8897 20.171 20.0303 20.0303C20.171 19.8897 20.25 19.6989 20.25 19.5V15.75C20.25 15.5511 20.171 15.3603 20.0303 15.2197C19.8897 15.079 19.6989 15 19.5 15ZM8.25 3.75H4.5C4.30109 3.75 4.11032 3.82902 3.96967 3.96967C3.82902 4.11032 3.75 4.30109 3.75 4.5V8.25C3.75 8.44891 3.82902 8.63968 3.96967 8.78033C4.11032 8.92098 4.30109 9 4.5 9C4.69891 9 4.88968 8.92098 5.03033 8.78033C5.17098 8.63968 5.25 8.44891 5.25 8.25V5.25H8.25C8.44891 5.25 8.63968 5.17098 8.78033 5.03033C8.92098 4.88968 9 4.69891 9 4.5C9 4.30109 8.92098 4.11032 8.78033 3.96967C8.63968 3.82902 8.44891 3.75 8.25 3.75Z"
        fill="#b4b4b4"
      />
    </Svg>
  );
};

export default Icon;
