import { useMemo, useState } from 'react'
import styled from 'styled-components'
import { Trade, TradeType, CurrencyAmount, Currency } from '@pancakeswap/sdk'
import { Button, Text, AutoRenewIcon, QuestionHelper } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import { Field } from 'state/swap/actions'
import { computeTradePriceBreakdown, formatExecutionPrice, warningSeverity } from 'utils/exchange'
import { AutoColumn } from 'components/Layout/Column'
import { AutoRow, RowBetween, RowFixed } from 'components/Layout/Row'
import { TOTAL_FEE, LP_HOLDERS_FEE, TREASURY_FEE, BUYBACK_FEE } from 'config/constants/info'
import FormattedPriceImpact from './FormattedPriceImpact'
import { StyledBalanceMaxMini, SwapCallbackError } from './styleds'

const SwapModalFooterContainer = styled(AutoColumn)`
  margin-top: 0;
  padding: 16px;
  border-radius: ${({ theme }) => theme.radii.default};
  background-color: ${({ theme }) => theme.colors.background};
`

export default function SwapModalFooter({
  trade,
  slippageAdjustedAmounts,
  isEnoughInputBalance,
  onConfirm,
  swapErrorMessage,
  disabledConfirm,
  allowedSlippage,
}: {
  trade: Trade<Currency, Currency, TradeType>
  slippageAdjustedAmounts: { [field in Field]?: CurrencyAmount<Currency> }
  isEnoughInputBalance: boolean
  onConfirm: () => void
  swapErrorMessage?: string | undefined
  disabledConfirm: boolean
  allowedSlippage: number
}) {
  const { t } = useTranslation()
  const [showInverted, setShowInverted] = useState<boolean>(false)
  const { priceImpactWithoutFee, realizedLPFee } = useMemo(() => computeTradePriceBreakdown(trade), [trade])
  const severity = warningSeverity(priceImpactWithoutFee)
  const isExactIn = trade.tradeType === TradeType.EXACT_INPUT

  const totalFeePercent = `${(TOTAL_FEE * 100).toFixed(2)}%`
  const lpHoldersFeePercent = `${(LP_HOLDERS_FEE * 100).toFixed(2)}%`
  const treasuryFeePercent = `${(TREASURY_FEE * 100).toFixed(4)}%`
  const buyBackFeePercent = `${(BUYBACK_FEE * 100).toFixed(4)}%`

  const amount =
    trade.tradeType === TradeType.EXACT_INPUT
      ? slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(6)
      : slippageAdjustedAmounts[Field.INPUT]?.toSignificant(6)
  const symbol =
    trade.tradeType === TradeType.EXACT_INPUT ? trade.outputAmount.currency.symbol : trade.inputAmount.currency.symbol

  const tradeInfoText =
    trade.tradeType === TradeType.EXACT_INPUT
      ? t(`Output is estimated. You will receive ${amount} ${symbol} at least or the transaction will revert.`)
      : t(`Input is estimated. You will sell at most or the transaction will revert.`)

  const [estimatedText, transactionRevertText] = tradeInfoText.split(`${amount} ${symbol}`)

  return (
    <>
      <SwapModalFooterContainer style={{ padding: '16px', backgroundColor: '#1a1a1a' }}>
        <RowBetween align="center" style={{ marginBottom: '8px' }}>
          <Text fontSize="12px" color="#71757A">
            {t('Expected Output')}
          </Text>
          <RowFixed>
            <Text fontSize="14px" fontWeight={600} color="#fff">
              {trade.tradeType === TradeType.EXACT_OUTPUT
                ? slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4) ?? '-'
                : slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4) ?? '-'}
            </Text>
            <Text fontSize="14px" marginLeft="4px" fontWeight={600} color="#fff">
              {trade.tradeType === TradeType.EXACT_OUTPUT
                ? trade.outputAmount.currency.symbol
                : trade.inputAmount.currency.symbol}
            </Text>
          </RowFixed>
        </RowBetween>

        <RowBetween style={{ marginBottom: '16px', paddingBottom: '8px', borderBottom: '0.5px solid #5967804d' }}>
          <RowFixed>
            <Text fontSize="12px" color="#71757A">
              {t('Price Impact')}
            </Text>
            {/* <QuestionHelper
              text={t('The difference between the market price and your price due to trade size.')}
              ml="4px"
            /> */}
          </RowFixed>
          <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
        </RowBetween>

        <RowBetween style={{ marginBottom: '8px' }}>
          <RowFixed>
            <Text fontSize="12px" color="#71757A">
              {trade.tradeType === TradeType.EXACT_INPUT ? t('Minimum received after') : t('Maximum sold')}
            </Text>
            {/* <QuestionHelper
              text={t(
                'Your transaction will revert if there is a large, unfavorable price movement before it is confirmed.',
              )}
              ml="4px"
            /> */}
          </RowFixed>
          <RowFixed>
            <Text fontSize="14px" fontWeight={600} color="#fff">
              {trade.tradeType === TradeType.EXACT_INPUT
                ? slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4) ?? '-'
                : slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4) ?? '-'}
            </Text>
            <Text fontSize="14px" marginLeft="4px" fontWeight={600} color="#fff">
              {trade.tradeType === TradeType.EXACT_INPUT
                ? trade.outputAmount.currency.symbol
                : trade.inputAmount.currency.symbol}
            </Text>
          </RowFixed>
        </RowBetween>
        <RowBetween>
          <RowFixed>
            <Text fontSize="12px" color="#71757A">
              {t(`slippage (${allowedSlippage / 100}%)`)}
            </Text>
            {/* <QuestionHelper
              text={
                <>
                  <Text mb="12px">{t('For each trade a 0.3% fee is paid')}</Text>
                  <Text>- {t('0.25% to LP token holders')}</Text>
                  <Text>- {t('0.05% towards Sandwich treasury')}</Text>
                </>
              }
              ml="4px"
            /> */}
          </RowFixed>
          <Text fontSize="14px" color="#71757A">
            {trade.inputAmount.currency.symbol}
          </Text>
        </RowBetween>
      </SwapModalFooterContainer>

      <AutoRow mt="14px" color="#71757A">
        {trade.tradeType === TradeType.EXACT_INPUT ? (
          <Text fontSize="12px" fontWeight={400} color="#596780">
            Output is estimated. You will receive{' '}
            <span style={{ color: '#fff' }}>
              {' '}
              {amount} {symbol}
            </span>{' '}
            at least or the transaction will revert.
          </Text>
        ) : (
          <Text fontSize="12px" fontWeight={400} color="#596780">
            Input is estimated. You will sell at most or the transaction will revert.
          </Text>
        )}
      </AutoRow>

      <AutoRow>
        <Button
          variant={severity > 2 ? 'danger' : 'primary'}
          onClick={onConfirm}
          height={52}
          disabled={disabledConfirm}
          mt="12px"
          id="confirm-swap-or-send"
          width="100%"
          style={{ backgroundColor: 'gradientCra', borderRadius: '26px' }}
        >
          {severity > 2 || (trade.tradeType === TradeType.EXACT_OUTPUT && !isEnoughInputBalance)
            ? t('Swap Anyway')
            : t('Confirm Swap')}
        </Button>

        {swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
      </AutoRow>
    </>
  )
}
