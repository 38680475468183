import { useMemo } from 'react'
import { Trade, TradeType, CurrencyAmount, Currency } from '@pancakeswap/sdk'
import { Button, Text, ErrorIcon, ArrowDownIcon, Swap as SwapUI, Flex, Skeleton, Box } from '@pancakeswap/uikit'
import { Field } from 'state/swap/actions'
import { useTranslation } from '@pancakeswap/localization'
import { computeTradePriceBreakdown, warningSeverity } from 'utils/exchange'
import { AutoColumn } from 'components/Layout/Column'
import { CurrencyLogo } from 'components/Logo'
import { RowBetween, RowFixed } from 'components/Layout/Row'
import truncateHash from '@pancakeswap/utils/truncateHash'
import { TruncatedText, SwapShowAcceptChanges } from './styleds'
import useRefreshBlockNumberID from '../hooks/useRefreshBlockNumber'

export default function SwapModalHeader({
  trade,
  slippageAdjustedAmounts,
  isEnoughInputBalance,
  recipient,
  showAcceptChanges,
  onAcceptChanges,
  allowedSlippage,
}: {
  trade: Trade<Currency, Currency, TradeType>
  slippageAdjustedAmounts: { [field in Field]?: CurrencyAmount<Currency> }
  isEnoughInputBalance: boolean
  recipient: string | null
  showAcceptChanges: boolean
  onAcceptChanges: () => void
  allowedSlippage: number
}) {
  const { t } = useTranslation()

  const { priceImpactWithoutFee } = useMemo(() => computeTradePriceBreakdown(trade), [trade])
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)
  const { refreshBlockNumber, isLoading } = useRefreshBlockNumberID()

  const inputTextColor =
    showAcceptChanges && trade.tradeType === TradeType.EXACT_OUTPUT && isEnoughInputBalance
      ? 'primary'
      : trade.tradeType === TradeType.EXACT_OUTPUT && !isEnoughInputBalance
      ? 'failure'
      : 'text'

  const amount =
    trade.tradeType === TradeType.EXACT_INPUT
      ? slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(6)
      : slippageAdjustedAmounts[Field.INPUT]?.toSignificant(6)
  const symbol =
    trade.tradeType === TradeType.EXACT_INPUT ? trade.outputAmount.currency.symbol : trade.inputAmount.currency.symbol

  const tradeInfoText =
    trade.tradeType === TradeType.EXACT_INPUT
      ? t('Output is estimated. You will receive at least or the transaction will revert.')
      : t('Input is estimated. You will sell at most or the transaction will revert.')

  const [estimatedText, transactionRevertText] = tradeInfoText.split(`${amount} ${symbol}`)

  const truncatedRecipient = recipient ? truncateHash(recipient) : ''

  const recipientInfoText = t('Output will be sent to %recipient%', {
    recipient: truncatedRecipient,
  })
  function capitalize(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
  }

  const [recipientSentToText, postSentToText] = recipientInfoText.split(truncatedRecipient)

  return (
    <AutoColumn gap="md">
      <Box display="flex" style={{ flexDirection: 'column', gap: '32px', position: 'relative' }}>
        <Flex flexDirection="column" gap="8px">
          <Text color="#b4b4b4" fontWeight={500} fontSize="12px">
            Swap
          </Text>
          <RowBetween
            align="center"
            backgroundColor="#1E212A"
            style={{ padding: '16px' }}
            borderRadius="30px"
            zIndex={2}
          >
            <RowFixed gap="4px">
              <TruncatedText fontSize="24px" fontWeight={600} color={inputTextColor}>
                {trade.inputAmount.toSignificant(6)}
              </TruncatedText>
            </RowFixed>
            <RowFixed>
              <CurrencyLogo currency={trade.inputAmount.currency} size="24px" />
              <Text
                style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
                fontSize="12px"
                fontWeight={600}
                ml="10px"
              >
                {capitalize(trade.inputAmount.currency.symbol)} {`$(${trade.inputAmount.currency.symbol})`}
              </Text>
            </RowFixed>
          </RowBetween>
        </Flex>

        {/* <Box position="absolute" left="calc(50% - 48px)" top="25%">
         
        </Box> */}

        <AutoColumn
          justify="space-between"
          style={{
            position: 'absolute',
            backgroundColor: '#1E212A',
            top: '39%',
            left: 'calc(50% - 64px)',
            width: '140px',
            height: '75px',
            borderRadius: '32px', // half of the height to make rounded corners
            overflow: 'hidden', // to hide parts of the circles outside the main container
          }}
        >
          {/* Left circle */}
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '-32px', // half of the circle's diameter to position it correctly
              width: '64px',
              height: '64px',
              backgroundColor: '#141414',
              borderRadius: '50%',
              transform: 'translateY(-50%)', // center the circle vertically
            }}
          />
          {/* Right circle */}
          <div
            style={{
              position: 'absolute',
              top: '50%',
              right: '-32px', // half of the circle's diameter to position it correctly
              width: '64px',
              height: '64px',
              backgroundColor: '#141414',
              borderRadius: '50%',
              transform: 'translateY(-50%)', // center the circle vertically
            }}
          />
          <RowBetween style={{ justifyContent: 'center' }}>
            <ArrowDownIcon />
          </RowBetween>
        </AutoColumn>

        <Flex flexDirection="column" gap="8px">
          <Text color="#b4b4b4" fontWeight={500} fontSize="12px">
            For
          </Text>
          <RowBetween
            align="center"
            backgroundColor="#1E212A"
            style={{ padding: '16px' }}
            borderRadius="30px"
            zIndex={2}
          >
            <RowFixed gap="4px">
              <TruncatedText
                fontSize="24px"
                fontWeight={600}
                color={
                  priceImpactSeverity > 2
                    ? 'failure'
                    : showAcceptChanges && trade.tradeType === TradeType.EXACT_INPUT
                    ? 'primary'
                    : 'text'
                }
              >
                {trade.outputAmount.toSignificant(6)}
              </TruncatedText>
            </RowFixed>
            <RowFixed>
              <CurrencyLogo currency={trade.outputAmount.currency} size="24px" />
              <Text
                style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
                fontSize="12px"
                fontWeight={600}
                ml="10px"
              >
                {capitalize(trade.outputAmount.currency.symbol)} {`$(${trade.outputAmount.currency.symbol})`}
              </Text>
            </RowFixed>
          </RowBetween>
        </Flex>
      </Box>

      <SwapUI.Info
        price={
          Boolean(trade) && (
            <>
              {isLoading ? (
                <Skeleton width="100%" ml="8px" height="24px" />
              ) : (
                <SwapUI.TradePrice price={trade?.executionPrice} isOnModal />
              )}
            </>
          )
        }
        allowedSlippage={allowedSlippage}
        showSlip={false}
      />

      {showAcceptChanges ? (
        <SwapShowAcceptChanges justify="flex-start" gap="0px">
          <RowBetween>
            <RowFixed>
              <ErrorIcon mr="8px" />
              <Text bold> {t('Price Updated')}</Text>
            </RowFixed>
            <Button onClick={onAcceptChanges}>{t('Accept')}</Button>
          </RowBetween>
        </SwapShowAcceptChanges>
      ) : null}
      <AutoColumn justify="flex-start" gap="sm" style={{ padding: ' 0' }}>
        {/* <RowFixed style={{ width: '100%' }}>
          <Text color="secondary" bold textTransform="uppercase">
            {t('Slippage Tolerance')}
          </Text>
          <Text bold color="primary" ml="auto" textAlign="end">
            {`${allowedSlippage / 100}%`}
          </Text>
        </RowFixed> */}
        {/* {trade.tradeType === TradeType.EXACT_OUTPUT && !isEnoughInputBalance && (
          <Text small color="failure" textAlign="left" style={{ width: '100%' }}>
            {t('Insufficient input token balance. Your transaction may fail.')}
          </Text>
        )} */}
        {/* <Text small color="textSubtle" textAlign="left" style={{ width: '100%' }}>
          {estimatedText}
          {transactionRevertText}
        </Text> */}
      </AutoColumn>
      {recipient !== null ? (
        <AutoColumn justify="flex-start" gap="sm" style={{ padding: '12px 0 0 0px' }}>
          <Text color="textSubtle">
            {recipientSentToText}
            <b title={recipient}>{truncatedRecipient}</b>
            {postSentToText}
          </Text>
        </AutoColumn>
      ) : null}
    </AutoColumn>
  )
}
