import { useCallback, useEffect, useState, useMemo, useContext } from 'react'
import { Currency, CurrencyAmount, Percent } from '@pancakeswap/sdk'
import {
  Button,
  ArrowDownIcon,
  Box,
  Skeleton,
  Swap as SwapUI,
  Message,
  MessageText,
  Text,
  useModal,
  Flex,
} from '@pancakeswap/uikit'
import { ImageUnlock } from 'views/PancakeSquad/styles'
import { useIsTransactionUnsupported } from 'hooks/Trades'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useTranslation } from '@pancakeswap/localization'
import { maxAmountSpend } from 'utils/maxAmountSpend'
import { useSwapActionHandlers } from 'state/swap/useSwapActionHandlers'
import AccessRisk from 'views/Swap/components/AccessRisk'

import CurrencyInputPanel from 'components/CurrencyInputPanel'
import { CommonBasesType } from 'components/SearchModal/types'
import { AutoRow } from 'components/Layout/Row'
import { AutoColumn } from 'components/Layout/Column'

import { useCurrency } from 'hooks/Tokens'
import { ApprovalState, useApproveCallbackFromTrade } from 'hooks/useApproveCallback'
import useWrapCallback, { WrapType } from 'hooks/useWrapCallback'

import { Field } from 'state/swap/actions'
import { useDerivedSwapInfo, useSwapState } from 'state/swap/hooks'
import { useExpertModeManager, useUserSlippageTolerance } from 'state/user/hooks'

import replaceBrowserHistory from '@pancakeswap/utils/replaceBrowserHistory'
import { currencyId } from 'utils/currencyId'

import CurrencyInputHeader from './CurrencyInputHeader'
import SwapCommitButton from './SwapCommitButton'
import useWarningImport from '../hooks/useWarningImport'
import useRefreshBlockNumberID from '../hooks/useRefreshBlockNumber'
import AddressInputPanel from './AddressInputPanel'
import AdvancedSwapDetailsDropdown from './AdvancedSwapDetailsDropdown'
import { ArrowWrapper, Wrapper } from './styleds'
import { useStableFarms } from '../StableSwap/hooks/useStableConfig'
import { isAddress } from '../../../utils'
import { SwapFeaturesContext } from '../SwapFeaturesContext'
import { useAkkaSwapInfo } from '../AkkaSwap/hooks/useAkkaSwapInfo'
import { useIsAkkaSwapModeActive, useIsAkkaSwapModeStatus } from 'state/global/hooks'
import AkkaSwapCommitButton from '../AkkaSwap/components/AkkaSwapCommitButton'
import AkkaAdvancedSwapDetailsDropdown from '../AkkaSwap/components/AkkaAdvancedSwapDetailsDropdown'
import styled from 'styled-components'
import { useApproveCallbackFromAkkaTrade } from '../AkkaSwap/hooks/useApproveCallbackFromAkkaTrade'
import { useBUSDCurrencyAmount } from 'hooks/useBUSDPrice'
import { getChain } from '@icecreamswap/constants'
import { SUPPORT_AKKA_ROUTER } from '../../../config/constants/supportChains'

function formatNumber(exponentialNumber: number): string {
  const str = exponentialNumber.toString()
  if (str.indexOf('e') !== -1) {
    const exponent = parseInt(str.split('-')[1], 10)
    const result = exponentialNumber.toFixed(exponent)
    return result
  }
  return str
}

const Label = styled(Text)`
  font-size: 12px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.secondary};
`
export default function SwapForm() {
  const { isAccessTokenSupported } = useContext(SwapFeaturesContext)
  const { t } = useTranslation()
  const { refreshBlockNumber, isLoading } = useRefreshBlockNumberID()
  const stableFarms = useStableFarms()
  const warningSwapHandler = useWarningImport()

  const { account, chainId } = useActiveWeb3React()
  const chainName = getChain(chainId).network

  // isAkkaSwapMode checks if this is akka router form or not from redux
  const [isAkkaSwapMode, toggleSetAkkaMode, toggleSetAkkaModeToFalse, toggleSetAkkaModeToTrue] =
    useIsAkkaSwapModeStatus()

  // isAkkaSwapActive checks if akka router is generally active or not
  const [isAkkaSwapActive, toggleSetAkkaActive, toggleSetAkkaActiveToFalse, toggleSetAkkaActiveToTrue] =
    useIsAkkaSwapModeActive()

  // for expert mode
  const [isExpertMode] = useExpertModeManager()

  // get custom setting values for user
  const [allowedSlippage] = useUserSlippageTolerance()

  // swap state & price data
  const {
    independentField,
    typedValue,
    recipient,
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useSwapState()

  const inputCurrency = useCurrency(inputCurrencyId)
  const outputCurrency = useCurrency(outputCurrencyId)
  const hasStableSwapAlternative = useMemo(() => {
    return stableFarms.some((stableFarm) => {
      const checkSummedToken0 = isAddress(stableFarm?.token0.address)
      const checkSummedToken1 = isAddress(stableFarm?.token1.address)
      return (
        (checkSummedToken0 === inputCurrencyId || checkSummedToken0 === outputCurrencyId) &&
        (checkSummedToken1 === outputCurrencyId || checkSummedToken1 === outputCurrencyId)
      )
    })
  }, [stableFarms, inputCurrencyId, outputCurrencyId])

  const currencies: { [field in Field]?: Currency } = useMemo(
    () => ({
      [Field.INPUT]: inputCurrency ?? undefined,
      [Field.OUTPUT]: outputCurrency ?? undefined,
    }),
    [inputCurrency, outputCurrency],
  )

  // Take swap information from pancakeswap router
  const {
    v2Trade,
    currencyBalances,
    parsedAmount,
    inputError: swapInputError,
  } = useDerivedSwapInfo(independentField, typedValue, inputCurrency, outputCurrency, recipient)

  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(currencies[Field.INPUT], currencies[Field.OUTPUT], typedValue)

  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const trade = showWrap ? undefined : v2Trade

  const parsedAmounts = showWrap
    ? {
        [Field.INPUT]: parsedAmount,
        [Field.OUTPUT]: parsedAmount,
      }
    : {
        [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
        [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount,
      }

  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers()

  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput],
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput],
  )

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ''
      : parsedAmounts[dependentField]?.toSignificant(6) ?? '',
  }

  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromTrade(trade, allowedSlippage, chainId)
  const [akkaApproval, akkaApproveCallback] = useApproveCallbackFromAkkaTrade(parsedAmounts[Field.INPUT])

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)
  const [akkaApprovalSubmitted, setAkkaApprovalSubmitted] = useState<boolean>(false)

  // Take swap information from AKKA router
  const {
    trade: akkaRouterTrade,
    currencyBalances: akkaCurrencyBalances,
    parsedAmount: akkaParsedAmount,
    inputError: akkaSwapInputError,
    mutateAkkaRoute,
    isLoading: isAkkaLoading,
  } = useAkkaSwapInfo(
    independentField,
    parsedAmounts[Field.INPUT]?.toExact(),
    inputCurrency,
    outputCurrency,
    allowedSlippage,
  )

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approval, approvalSubmitted])

  useEffect(() => {
    if (akkaApproval === ApprovalState.PENDING) {
      setAkkaApprovalSubmitted(true)
    }
  }, [akkaApproval, akkaApprovalSubmitted])

  useEffect(() => {
    if (akkaApproval === ApprovalState.APPROVED) {
      mutateAkkaRoute()
    }
  }, [akkaApproval])

  const [, , isAkkaConfirmModalOpen] = useModal('confirmSwapModal')
  useEffect(() => {
    if (isAkkaConfirmModalOpen === false) {
      mutateAkkaRoute()
    }
  }, [isAkkaConfirmModalOpen])

  const maxAmountInput: CurrencyAmount<Currency> | undefined = maxAmountSpend(currencyBalances[Field.INPUT])
  const atMaxAmountInput = Boolean(maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput))

  const handleInputSelect = useCallback(
    (newCurrencyInput) => {
      setApprovalSubmitted(false) // reset 2 step UI for approvals
      setAkkaApprovalSubmitted(false)

      onCurrencySelection(Field.INPUT, newCurrencyInput)

      warningSwapHandler(newCurrencyInput)

      const newCurrencyInputId = currencyId(newCurrencyInput)
      if (newCurrencyInputId === outputCurrencyId) {
        replaceBrowserHistory('outputCurrency', inputCurrencyId)
      }
      replaceBrowserHistory('inputCurrency', newCurrencyInputId)
    },
    [inputCurrencyId, outputCurrencyId, onCurrencySelection, warningSwapHandler],
  )

  const handleMaxInput = useCallback(() => {
    if (maxAmountInput) {
      onUserInput(Field.INPUT, maxAmountInput.toExact())
    }
  }, [maxAmountInput, onUserInput])

  const handleOutputSelect = useCallback(
    (newCurrencyOutput) => {
      onCurrencySelection(Field.OUTPUT, newCurrencyOutput)
      warningSwapHandler(newCurrencyOutput)

      const newCurrencyOutputId = currencyId(newCurrencyOutput)
      if (newCurrencyOutputId === inputCurrencyId) {
        replaceBrowserHistory('inputCurrency', outputCurrencyId)
      }
      replaceBrowserHistory('outputCurrency', newCurrencyOutputId)
    },

    [inputCurrencyId, outputCurrencyId, onCurrencySelection, warningSwapHandler],
  )

  const handlePercentInput = useCallback(
    (percent) => {
      if (maxAmountInput) {
        onUserInput(Field.INPUT, maxAmountInput.multiply(new Percent(percent, 100)).toExact())
      }
    },
    [maxAmountInput, onUserInput],
  )

  const swapIsUnsupported = useIsTransactionUnsupported(currencies?.INPUT, currencies?.OUTPUT)

  const hasAmount = Boolean(parsedAmount)

  const onRefreshPrice = useCallback(() => {
    if (hasAmount) {
      refreshBlockNumber()
    }
  }, [hasAmount, refreshBlockNumber])

  const inputAmountInDollar = useBUSDCurrencyAmount(
    true ? inputCurrency : undefined,
    Number.isFinite(+formattedAmounts[Field.INPUT]) ? +formattedAmounts[Field.INPUT] : undefined,
  )
  const outputAmountInDollar = useBUSDCurrencyAmount(
    true ? outputCurrency : undefined,
    Number.isFinite(
      +(isAkkaSwapMode && isAkkaSwapActive && akkaRouterTrade && akkaRouterTrade?.route && typedValue !== ''
        ? akkaRouterTrade.route.returnAmountWithoutTax
        : formattedAmounts[Field.OUTPUT]),
    )
      ? +(isAkkaSwapMode && isAkkaSwapActive && akkaRouterTrade && akkaRouterTrade?.route && typedValue !== ''
          ? akkaRouterTrade.route.returnAmountWithoutTax
          : formattedAmounts[Field.OUTPUT])
      : undefined,
  )
  const outputAmountInDollarWithTax = useBUSDCurrencyAmount(
    true ? outputCurrency : undefined,
    Number.isFinite(
      +(isAkkaSwapMode && isAkkaSwapActive && akkaRouterTrade && akkaRouterTrade?.route && typedValue !== ''
        ? akkaRouterTrade.route.returnAmount
        : formattedAmounts[Field.OUTPUT]),
    )
      ? +(isAkkaSwapMode && isAkkaSwapActive && akkaRouterTrade && akkaRouterTrade?.route && typedValue !== ''
          ? akkaRouterTrade.route.returnAmount
          : formattedAmounts[Field.OUTPUT])
      : undefined,
  )

  // whether AKKA router supports the current chain
  const akkaRouterSupported = SUPPORT_AKKA_ROUTER.includes(chainId)

  return (
    <>
      <CurrencyInputHeader
        title={t(akkaRouterSupported && isAkkaSwapActive ? 'DEX Aggregator' : 'Exchange')}
        subtitle={
          akkaRouterSupported && isAkkaSwapActive
            ? t('Best trades on %chain%', { chain: chainName.charAt(0).toUpperCase() + chainName.slice(1) })
            : t('Trade tokens in an instant')
        }
        hasAmount={hasAmount}
        onRefreshPrice={onRefreshPrice}
        mutateAkkaRoute={mutateAkkaRoute}
      />
      <Wrapper id="swap-page" px="0px !important" pb="4px !important" backgroundColor="backgroundAlt" width="100%">
        <AutoColumn gap="sm">
          <Box display="flex" style={{ flexDirection: 'column', gap: '32px', position: 'relative' }}>
            <CurrencyInputPanel
              label={independentField === Field.OUTPUT && !showWrap && trade ? t('From (estimated)') : t('From')}
              value={formattedAmounts[Field.INPUT]}
              showMaxButton={!atMaxAmountInput}
              showQuickInputButton
              currency={currencies[Field.INPUT]}
              onUserInput={handleTypeInput}
              onPercentInput={handlePercentInput}
              onMax={handleMaxInput}
              onCurrencySelect={handleInputSelect}
              otherCurrency={currencies[Field.OUTPUT]}
              id="swap-currency-input"
              showCommonBases
              commonBasesType={CommonBasesType.SWAP_LIMITORDER}
              title="You pay"
            />

            <AutoColumn
              justify="space-between"
              style={{
                position: 'absolute',
                backgroundColor: '#141414',
                bottom: ' calc(50% - 58px)',
                left: 'calc(50% - 70px)',
                width: '140px',
                height: '90px',
                borderRadius: '32px', // half of the height to make rounded corners
                overflow: 'hidden', // to hide parts of the circles outside the main container
              }}
            >
              {/* Left circle */}
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '-32px', // half of the circle's diameter to position it correctly
                  width: '64px',
                  height: '64px',
                  backgroundColor: '#1a1a1a',
                  borderRadius: '50%',
                  transform: 'translateY(-50%)', // center the circle vertically
                }}
              />
              {/* Right circle */}
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  right: '-32px', // half of the circle's diameter to position it correctly
                  width: '64px',
                  height: '64px',
                  backgroundColor: '#1a1a1a',
                  borderRadius: '50%',
                  transform: 'translateY(-50%)', // center the circle vertically
                }}
              />
              <AutoRow justify={isExpertMode ? 'space-between' : 'center'} style={{ position: 'relative', zIndex: 4 }}>
                <SwapUI.SwitchButton
                  onClick={() => {
                    setApprovalSubmitted(false) // reset 2 step UI for approvals
                    setAkkaApprovalSubmitted(false)
                    onSwitchTokens()
                    replaceBrowserHistory('inputCurrency', outputCurrencyId)
                    replaceBrowserHistory('outputCurrency', inputCurrencyId)
                  }}
                />
                {recipient === null && !showWrap && isExpertMode ? (
                  <Button variant="text" id="add-recipient-button" onClick={() => onChangeRecipient('')}>
                    {t('+ Add a send (optional)')}
                  </Button>
                ) : null}
              </AutoRow>
            </AutoColumn>

            <CurrencyInputPanel
              value={
                isAkkaSwapMode && isAkkaSwapActive && akkaRouterTrade && akkaRouterTrade?.route && typedValue !== ''
                  ? formatNumber(Number(akkaRouterTrade.route.returnAmount))
                  : formattedAmounts[Field.OUTPUT]
              }
              onUserInput={handleTypeOutput}
              label={independentField === Field.INPUT && !showWrap && trade ? t('To (estimated)') : t('To')}
              showMaxButton={false}
              currency={currencies[Field.OUTPUT]}
              onCurrencySelect={handleOutputSelect}
              otherCurrency={currencies[Field.INPUT]}
              id="swap-currency-output"
              showCommonBases
              commonBasesType={CommonBasesType.SWAP_LIMITORDER}
              disabled={isAkkaSwapMode && isAkkaSwapActive}
              title="You receive"
            />
          </Box>

          {isAccessTokenSupported && (
            <Box>
              <AccessRisk inputCurrency={currencies[Field.INPUT]} outputCurrency={currencies[Field.OUTPUT]} />
            </Box>
          )}

          {isExpertMode && recipient !== null && !showWrap ? (
            <>
              <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                <ArrowWrapper clickable={false}>
                  <ArrowDownIcon width="16px" />
                </ArrowWrapper>
                <Button variant="text" id="remove-recipient-button" onClick={() => onChangeRecipient(null)}>
                  {t('- Remove send')}
                </Button>
              </AutoRow>
              <AddressInputPanel id="recipient" value={recipient} onChange={onChangeRecipient} />
            </>
          ) : null}

          {showWrap ? null : (
            <SwapUI.Info
              price={
                Boolean(trade) && (
                  <Flex width="100%" alignItems="start">
                    <SwapUI.InfoLabel>{t('Price')}</SwapUI.InfoLabel>
                    {isLoading ? (
                      <Skeleton width="100%" ml="8px" height="24px" />
                    ) : (
                      <SwapUI.TradePrice price={trade?.executionPrice} />
                    )}
                  </Flex>
                )
              }
              allowedSlippage={allowedSlippage}
            />
          )}
        </AutoColumn>
        {hasStableSwapAlternative && (
          <AutoColumn>
            <Message variant="warning" my="16px">
              <MessageText>{t('Trade stablecoins in StableSwap with lower slippage and trading fees!')}</MessageText>
            </Message>
          </AutoColumn>
        )}
        <Box mt="0.25rem">
          {isAkkaSwapMode && isAkkaSwapActive && akkaRouterTrade ? (
            <AkkaSwapCommitButton
              account={account}
              approval={akkaApproval}
              approveCallback={akkaApproveCallback}
              approvalSubmitted={akkaApprovalSubmitted}
              currencies={currencies}
              isExpertMode={isExpertMode}
              trade={akkaRouterTrade}
              swapInputError={akkaSwapInputError}
              currencyBalances={akkaCurrencyBalances}
              allowedSlippage={allowedSlippage}
              onUserInput={onUserInput}
              inputAmountInDollar={inputAmountInDollar}
              outputAmountInDollar={outputAmountInDollar}
              outputAmountInDollarWithTax={outputAmountInDollarWithTax}
              isLoading={isAkkaLoading}
            />
          ) : (
            <SwapCommitButton
              swapIsUnsupported={swapIsUnsupported}
              account={account}
              showWrap={showWrap}
              wrapInputError={wrapInputError}
              onWrap={onWrap}
              wrapType={wrapType}
              parsedIndepentFieldAmount={parsedAmounts[independentField]}
              approval={approval}
              approveCallback={approveCallback}
              approvalSubmitted={approvalSubmitted}
              currencies={currencies}
              isExpertMode={isExpertMode}
              trade={trade}
              swapInputError={swapInputError}
              currencyBalances={currencyBalances}
              recipient={recipient}
              allowedSlippage={allowedSlippage}
              onUserInput={onUserInput}
              isLoading={isAkkaLoading}
            />
          )}
        </Box>

        {(!isAkkaSwapMode || !isAkkaSwapActive) && !swapIsUnsupported
          ? trade && (
              <Box mt="14px" width="100% !important">
                <AdvancedSwapDetailsDropdown trade={trade} allowedSlippage={allowedSlippage} />
              </Box>
            )
          : ''}
        {isAkkaSwapMode && isAkkaSwapActive && akkaRouterTrade && akkaRouterTrade?.route && typedValue && (
          <Box mt="14px" width="100% !important">
            <AkkaAdvancedSwapDetailsDropdown
              route={akkaRouterTrade.route}
              inputAmountInDollar={inputAmountInDollar}
              outputAmountInDollar={outputAmountInDollar}
              outputAmountInDollarWithTax={outputAmountInDollarWithTax}
            />
          </Box>
        )}
      </Wrapper>
    </>
  )
}
