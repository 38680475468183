import { useContext } from 'react'
import { Currency } from '@pancakeswap/sdk'
import { Box, Flex, BottomDrawer, useMatchBreakpoints, Swap as SwapUI, Link, Message, Text } from '@pancakeswap/uikit'
import { EXCHANGE_DOCS_URLS } from 'config/constants'
import { AppBody } from 'components/App'
import NextImage from 'next/image'

import { useCurrency } from '../../hooks/Tokens'
import { Field } from '../../state/swap/actions'
import { useSwapState, useSingleTokenSwapInfo, useDerivedSwapInfo } from '../../state/swap/hooks'
import Page from '../Page'
import PriceChartContainer from './components/Chart/PriceChartContainer'

import SwapForm from './components/SwapForm'
import StableSwapFormContainer from './StableSwap'
import { StyledInputCurrencyWrapper, StyledSwapContainer } from './styles'
import SwapTab, { SwapType } from './components/SwapTab'
import { SwapFeaturesContext } from './SwapFeaturesContext'
import { useWeb3React } from '@pancakeswap/wagmi'
import useWrapCallback, { WrapType } from 'hooks/useWrapCallback'
import { useSupportedChainList, useSupportedChains } from 'hooks/useSupportedChains'
import { useBalance } from 'wagmi'
import chainName from 'config/constants/chainName'
import { useTranslation } from '@pancakeswap/localization'
// import CraMascot from '../../../public/images/mascot.png'

export default function Swap() {
  const { t } = useTranslation()
  const { isDesktop, isMobile } = useMatchBreakpoints()
  const { isChartExpanded, isChartDisplayed, setIsChartDisplayed, setIsChartExpanded, isChartSupported } =
    useContext(SwapFeaturesContext)

  const { account } = useWeb3React()

  // swap state & price data
  const {
    independentField,
    typedValue,
    recipient,
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useSwapState()

  const inputCurrency = useCurrency(inputCurrencyId)
  const outputCurrency = useCurrency(outputCurrencyId)
  const currencies: { [field in Field]?: Currency } = {
    [Field.INPUT]: inputCurrency ?? undefined,
    [Field.OUTPUT]: outputCurrency ?? undefined,
  }

  const { chainId: walletChainId } = useWeb3React()

  // Take swap information from pancakeswap router
  const {
    v2Trade,
    parsedAmount,
    inputError: swapInputError,
  } = useDerivedSwapInfo(independentField, typedValue, inputCurrency, outputCurrency, recipient)

  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(currencies[Field.INPUT], currencies[Field.OUTPUT], typedValue)

  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const trade = showWrap ? undefined : v2Trade
  const parsedAmounts = showWrap
    ? {
        [Field.INPUT]: parsedAmount,
        [Field.OUTPUT]: parsedAmount,
      }
    : {
        [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
        [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount,
      }
  const { isConnected } = useWeb3React()

  const singleTokenPrice = useSingleTokenSwapInfo(inputCurrencyId, inputCurrency, outputCurrencyId, outputCurrency)
  const supportedChains = useSupportedChains()
  const supportedChainNames = useSupportedChainList()
  const balance = useBalance({ addressOrName: account })
  const isChainSupported = walletChainId ? supportedChains.includes(walletChainId) : true
  const isConnectedAndHasNoBalance = isConnected && balance.data?.value?.isZero()

  return (
    <Page removePadding={isChartDisplayed} hideFooterOnDesktop={isChartExpanded}>
      {isConnectedAndHasNoBalance && (
        <Message variant="info" mb="16px">
          <Text fontSize={['12px', '12px', '14px', '14px']}>
            {t("It looks like you don't have any %chain% tokens. Simply", { chain: chainName[walletChainId] })}{' '}
            <Link href="/bridge" display="inline-flex" fontSize={['12px', '12px', '14px', '14px']}>
              {t('bridge')}
            </Link>{' '}
            {t('any token to %chain% and recieve a free gasdrop.', { chain: chainName[walletChainId] })}
          </Text>
        </Message>
      )}

      <Flex
        // marginBottom="4em"
        width="100%"
        height="fit-content"
        maxWidth="1200px"
        // height="100%"
        justifyContent="center"
        alignItems="end"
        gap="12px"
        padding="0px !important"
      >
        {isDesktop && isChartSupported && (
          <PriceChartContainer
            inputCurrencyId={inputCurrencyId}
            inputCurrency={currencies[Field.INPUT]}
            outputCurrencyId={outputCurrencyId}
            outputCurrency={currencies[Field.OUTPUT]}
            isChartExpanded={isChartExpanded}
            setIsChartExpanded={setIsChartExpanded}
            isChartDisplayed={isChartDisplayed}
            currentSwapPrice={singleTokenPrice}
          />
        )}

        {isChartSupported && (
          <BottomDrawer
            style={{
              maxHeight: '70vh',
            }}
            content={
              <PriceChartContainer
                inputCurrencyId={inputCurrencyId}
                inputCurrency={currencies[Field.INPUT]}
                outputCurrencyId={outputCurrencyId}
                outputCurrency={currencies[Field.OUTPUT]}
                isChartExpanded={isChartExpanded}
                setIsChartExpanded={setIsChartExpanded}
                isChartDisplayed={isChartDisplayed}
                currentSwapPrice={singleTokenPrice}
                isMobile={!isDesktop}
              />
            }
            isOpen={isChartDisplayed}
            setIsOpen={setIsChartDisplayed}
          />
        )}
        {isChainSupported &&
          (!isDesktop ? (
            <Flex
              position="relative"
              marginTop={isMobile ? '106px' : '213px'}
              backgroundColor="#1a1a1a"
              borderRadius="24px"
              width={isMobile ? '100%' : '480px'}
            >
              <Flex
                position="absolute"
                top={isMobile ? '-106px' : '-213px'}
                left={isMobile ? '35%' : '25%'}
                zIndex={99}
                style={{ pointerEvents: 'none' }}
              >
                <NextImage
                  src="https://res.cloudinary.com/dazqakdmu/image/upload/v1723512727/craswap/mascot.png"
                  alt="mascot"
                  width={isMobile ? 115 : 230}
                  height={isMobile ? 127.5 : 255}
                  style={{ pointerEvents: 'none' }}
                  priority
                />
              </Flex>
              <AppBody>
                <SwapTab>
                  {(swapTypeState) =>
                    swapTypeState === SwapType.STABLE_SWAP ? <StableSwapFormContainer /> : <SwapForm />
                  }
                </SwapTab>
              </AppBody>
            </Flex>
          ) : (
            <Flex justifyContent={isChartDisplayed ? 'end' : 'center'} width="480px" gap="24px !important">
              <Flex
                justifyContent="center"
                alignItems="center"
                width="100%"
                position="relative"
                marginTop="170px"
                backgroundColor="#1a1a1a"
                borderRadius="24px"
              >
                <Flex position="absolute" top="-213px" zIndex={99} style={{ pointerEvents: 'none' }}>
                  <NextImage
                    src="https://res.cloudinary.com/dazqakdmu/image/upload/v1723512727/craswap/mascot.png"
                    alt="mascot"
                    width={230}
                    height={255}
                    style={{ pointerEvents: 'none' }}
                    priority
                  />
                </Flex>
                <AppBody>
                  <SwapTab>
                    {(swapTypeState) =>
                      swapTypeState === SwapType.STABLE_SWAP ? <StableSwapFormContainer /> : <SwapForm />
                    }
                  </SwapTab>
                </AppBody>
              </Flex>
            </Flex>
          ))}
      </Flex>
    </Page>
  )
}
