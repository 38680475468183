import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none" {...props}>
      <path
        d="M7.56764 16L14.5776 16L14.5776 14L7.56764 14L7.56764 11L3.57764 15L7.56764 19L7.56764 16ZM21.5776 9L17.5876 5L17.5876 8L10.5776 8L10.5776 10L17.5876 10L17.5876 13L21.5776 9ZM7.56764 16L14.5776 16L14.5776 14L7.56764 14L7.56764 11L3.57764 15L7.56764 19L7.56764 16ZM21.5776 9L17.5876 5L17.5876 8L10.5776 8L10.5776 10L17.5876 10L17.5876 13L21.5776 9Z"
        fill="#FBFBFB"
      />
    </Svg>
  );
};

export default Icon;
