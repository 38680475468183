import { Swap, Flex, IconButton, NotificationDot, useModal, Text } from '@pancakeswap/uikit'
import TransactionsModal from 'components/App/Transactions/TransactionsModal'
import GlobalSettings from 'components/Menu/GlobalSettings'
// import RefreshIcon from 'components/Svg/RefreshIcon'
import { ReactElement, useCallback, useContext } from 'react'
import { useExpertModeManager } from 'state/user/hooks'
import styled from 'styled-components'
import { SettingsMode } from '../../../components/Menu/GlobalSettings/types'
import { SwapFeaturesContext } from '../SwapFeaturesContext'
import { ChartIcon, ChartActiveIcon, HistoryIcon, SettingIcon } from '../../../../public/icon'

interface Props {
  title: string | ReactElement
  subtitle: string
  noConfig?: boolean
  setIsChartDisplayed?: React.Dispatch<React.SetStateAction<boolean>>
  isChartDisplayed?: boolean
  hasAmount: boolean
  onRefreshPrice: () => void
  mutateAkkaRoute?: () => void
}

const ColoredIconButton = styled(IconButton)`
  color: ${({ theme }) => theme.colors.textSubtle};
`

const CurrencyInputHeader: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  subtitle,
  hasAmount,
  onRefreshPrice,
  mutateAkkaRoute,
}) => {
  const { isChartSupported, isChartDisplayed, setIsChartDisplayed } = useContext(SwapFeaturesContext)
  const [expertMode] = useExpertModeManager()
  const toggleChartDisplayed = () => {
    setIsChartDisplayed((currentIsChartDisplayed) => !currentIsChartDisplayed)
  }
  const [onPresentTransactionsModal] = useModal(<TransactionsModal />)
  const handleOnClick = useCallback(() => {
    mutateAkkaRoute?.()
    onRefreshPrice?.()
  }, [onRefreshPrice])

  return (
    <Swap.CurrencyInputHeader
      title={
        <Flex width="100%" alignItems="center" justifyContent="space-between">
          <Flex gap="8px">
            {isChartSupported && setIsChartDisplayed && (
              <ColoredIconButton
                onClick={toggleChartDisplayed}
                variant="text"
                scale="md"
                aria-label="Toggle chart"
                style={{ background: 'none' }}
              >
                {isChartDisplayed ? <ChartActiveIcon /> : <ChartIcon />}
              </ColoredIconButton>
            )}
            <Flex width={48} height={48} />
          </Flex>
          <Flex flexDirection="column" alignItems="flex-start" mr={18}>
            <Swap.CurrencyInputHeaderTitle>
              <Text fontSize={['16px', null, null, '24px']} fontWeight={700}>
                {title}
              </Text>
            </Swap.CurrencyInputHeaderTitle>
          </Flex>
          <Flex gap="8px">
            {/* {isChartSupported && setIsChartDisplayed && (
              <ColoredIconButton
                onClick={toggleChartDisplayed}
                variant="text"
                scale="sm"
                aria-label="Toggle chart"
                style={{ background: 'none' }}
              >
                {isChartDisplayed ? <ChartActiveIcon /> : <ChartIcon />}
              </ColoredIconButton>
            )} */}
            <IconButton onClick={onPresentTransactionsModal} variant="text" scale="md" style={{ background: 'none' }}>
              <HistoryIcon />
            </IconButton>
            <NotificationDot show={expertMode}>
              <GlobalSettings
                color="textSubtle"
                mr="0"
                mode={SettingsMode.SWAP_LIQUIDITY}
                aria-label="Settings"
                icon={<SettingIcon />}
              />
            </NotificationDot>
            {/* <IconButton variant="text" scale="sm" onClick={handleOnClick} style={{ background: 'none' }}>
              <RefreshIcon disabled={!hasAmount} color="textSubtle" width="27px" aria-label="Refresh price" />
            </IconButton> */}
          </Flex>
        </Flex>
      }
      subtitle={
        <Flex>
          <Swap.CurrencyInputHeaderSubTitle>
            <Text fontSize="14px" fontWeight={400} color="#90A3BF" />
          </Swap.CurrencyInputHeaderSubTitle>
        </Flex>
      }
    />
  )
}

export default CurrencyInputHeader
