import { Box, ButtonMenu, ButtonMenuItem, Flex, Text } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import { useState, memo } from 'react'
import { useFetchPairPrices } from 'state/swap/hooks'
import dynamic from 'next/dynamic'
import { PairDataTimeWindowEnum } from 'state/swap/types'
import NoChartAvailable from './NoChartAvailable'
import PairPriceDisplay from '../../../../components/PairPriceDisplay'
import { getTimeWindowChange } from './utils'

const SwapLineChart = dynamic(() => import('./SwapLineChart'), {
  ssr: false,
})

const BasicChart = ({
  token0Address,
  token1Address,
  isChartExpanded,
  inputCurrency,
  outputCurrency,
  isMobile,
  currentSwapPrice,
}) => {
  const [timeWindow, setTimeWindow] = useState<PairDataTimeWindowEnum>(0)

  const { pairPrices = [], pairId } = useFetchPairPrices({
    token0Address,
    token1Address,
    timeWindow,
    currentSwapPrice,
  })
  const [hoverValue, setHoverValue] = useState<number | undefined>()
  const [hoverDate, setHoverDate] = useState<string | undefined>()
  const valueToDisplay = hoverValue || pairPrices[pairPrices.length - 1]?.value
  const { changePercentage, changeValue } = getTimeWindowChange(pairPrices)
  const isChangePositive = changeValue >= 0
  const chartHeight = isChartExpanded ? 'calc(100vh - 220px)' : '378px'
  const {
    t,
    currentLanguage: { locale },
  } = useTranslation()
  const currentDate = new Date().toLocaleString(locale, {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  })

  // Sometimes we might receive array full of zeros for obscure tokens while trying to derive data
  // In that case chart is not useful to users
  const isBadData =
    pairPrices &&
    pairPrices.length > 0 &&
    pairPrices.every(
      (price) => !price.value || price.value === 0 || price.value === Infinity || Number.isNaN(price.value),
    )

  if (isBadData) {
    return (
      <NoChartAvailable
        token0Address={token0Address}
        token1Address={token1Address}
        pairAddress={pairId}
        isMobile={isMobile}
      />
    )
  }

  return (
    <>
      <Flex
        flexDirection={['column', null, null, null, null, null, 'row']}
        alignItems={['flex-start', null, null, null, null, null, 'center']}
        justifyContent="space-between"
        px="24px"
      >
        <Flex flexDirection="column">
          <PairPriceDisplay value={pairPrices?.length > 0 && valueToDisplay}>
            {inputCurrency && (
              <Text color="text" fontSize="14px" fontWeight={400}>
                {outputCurrency ? `${inputCurrency.symbol}/${outputCurrency.symbol}` : inputCurrency.symbol}
              </Text>
            )}
            <Text color={isChangePositive ? 'success' : 'failure'} fontSize="14px" fontWeight={400} ml="4px">
              {`${changePercentage}%`}
            </Text>
          </PairPriceDisplay>
          <Text small color="textSubtle3">
            {hoverDate || currentDate}
          </Text>
        </Flex>
        <Box>
          <ButtonMenu
            activeIndex={timeWindow}
            onItemClick={setTimeWindow}
            scale="sm"
            style={{
              border: '1.5px #262626 !important',
              backgroundColor: 'transparent',
              borderRadius: '24px',
              padding: '6px',
              gap: '16px',
            }}
          >
            <ButtonMenuItem
              variant="text"
              style={{
                background: timeWindow === 0 ? '#F476211A' : 'transparent',
                color: timeWindow === 0 && '#FDE53A',
                border: `1px solid ${timeWindow === 0 ? '#F4762166' : 'transparent'}`,
                borderRadius: '18px',
                padding: '10px 20px',
                fontSize: '10px !important',
                fontWeight: 500,
              }}
            >
              {t('24H')}
            </ButtonMenuItem>
            <ButtonMenuItem
              variant="text"
              style={{
                background: timeWindow === 1 ? '#F476211A' : 'transparent',
                color: timeWindow === 1 && '#FDE53A',
                border: `1px solid ${timeWindow === 1 ? '#F4762166' : 'transparent'}`,
                borderRadius: '18px',
                padding: '10px 20px',
                fontSize: '10px !important',
                fontWeight: 500,
              }}
            >
              {t('1W')}
            </ButtonMenuItem>
            <ButtonMenuItem
              variant="text"
              style={{
                background: timeWindow === 2 ? '#F476211A' : 'transparent',
                color: timeWindow === 2 && '#FDE53A',
                border: `1px solid ${timeWindow === 2 ? '#F4762166' : 'transparent'}`,
                borderRadius: '18px',
                padding: '10px 20px',
                fontSize: '10px !important',
                fontWeight: 500,
              }}
            >
              {t('1M')}
            </ButtonMenuItem>
            <ButtonMenuItem
              variant="text"
              style={{
                background: timeWindow === 3 ? '#F476211A' : 'transparent',
                color: timeWindow === 3 && '#FDE53A',
                border: `1px solid ${timeWindow === 3 ? '#F4762166' : 'transparent'}`,
                borderRadius: '18px',
                padding: '10px 20px',
                fontSize: '10px !important',
                fontWeight: 500,
              }}
            >
              {t('1Y')}
            </ButtonMenuItem>
          </ButtonMenu>
        </Box>
      </Flex>
      <Box height={isMobile ? '400px' : chartHeight} p={isMobile ? '0px' : '16px'} width="100%">
        <SwapLineChart
          data={pairPrices}
          setHoverValue={setHoverValue}
          setHoverDate={setHoverDate}
          isChangePositive={isChangePositive}
          timeWindow={timeWindow}
        />
      </Box>
    </>
  )
}

export default memo(BasicChart, (prev, next) => {
  return (
    prev.token0Address === next.token0Address &&
    prev.token1Address === next.token1Address &&
    prev.isChartExpanded === next.isChartExpanded &&
    prev.isMobile === next.isMobile &&
    prev.isChartExpanded === next.isChartExpanded &&
    ((prev.currentSwapPrice !== null &&
      next.currentSwapPrice !== null &&
      prev.currentSwapPrice[prev.token0Address] === next.currentSwapPrice[next.token0Address] &&
      prev.currentSwapPrice[prev.token1Address] === next.currentSwapPrice[next.token1Address]) ||
      (prev.currentSwapPrice === null && next.currentSwapPrice === null))
  )
})
